<template>
  <div>
    <b-card title="Eliminazione file DDT dal dispositivo">
      <b-card-text>
        Cliccare il pulsante qui in basso per eliminare <b>tutti i DDT scaricati</b> dal dispositivo.
      </b-card-text>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.modal-confirm-delivery
        variant="danger"
        class="btn-icon mr-50"
      >
        <feather-icon
          icon="Trash2Icon"
          class="mr-50"
        />
        <span class="align-middle">ELIMINA I DDT</span>
      </b-button>
    </b-card>

    <!-- Modale conferma pulizia DDT -->
    <b-modal
      id="modal-confirm-delivery"
      ref="confirm-modal"
      centered
      title="Sei sicuro?"
      ok-title="CONFERMA"
      ok-variant="danger"
      cancel-title="CHIUDI"
      cancel-variant="outline-secondary"
      @ok="handleConfirmOk"
    >

      <b-overlay
        :show="showOverlayConfirmModal"
        variant="white"
      >
        <p>Verranno eliminati tutti i DDT dal dispositivo.<br>
          L'operazione è irreversibile.<br>
          Non sarà possibile completare la firma per i DDT già scaricati.<br>
          Si aprirà l'app UbSign per completare l'operazione.
        </p>

      </b-overlay>
    </b-modal>
    <!-- END | Modale conferma consegna ordine -->

  </div>
</template>

<script>
import {
  BButton, BCard, BCardText, BFormGroup, BFormInput, BModal, BOverlay, VBModal, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { openApp } from '@mixins/ubsign'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BOverlay,
    BButton,
    BCard,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [openApp],
  data() {
    return {
      locale: 'it',
      showOverlayConfirmModal: false,
    }
  },
  methods: {
    handleConfirmOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleConfirmCleaning()
    },
    handleConfirmCleaning() {
      this.showOverlayConfirmModal = true

      this.openApp('0', 0, 2)

      this.showOverlayConfirmModal = false
      this.$nextTick(() => {
        this.$refs['confirm-modal'].toggle('#toggle-btn')
      })
    },
  },
}
</script>

<style>

</style>
